import styled from "styled-components"
import { mq, typography } from "styles"
import { Link } from "gatsby"
import { animated } from "react-spring"
import { $Mode } from "typings/modules"

export const ProductItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Title = styled(Link)<$Mode & { $isGrid: boolean }>`
  color: ${({ $mode }) => `var(--text-headline-${$mode})`};
  display: inline-block;
  ${({ $isGrid }) => ($isGrid ? typography.body : typography.h5)};
  text-decoration: none;
  padding-top: 16px;
  &:hover {
    text-decoration: underline;
  }

  ${mq.minWidth("md")} {
    ${typography.h5};
  }
`

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
  ${typography.caption};
`

export const SubTitlePrice = styled.div<{ $isGrid: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
  padding: 4px 0 2px;

  ${({ $isGrid }) =>
    $isGrid &&
    `
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `};
`

export const SubtitleContainer = styled.div<{ $isGrid: boolean }>`
  display: flex;
  width: ${({ $isGrid }) => ($isGrid ? "100%" : "auto")};
  justify-content: space-between;
`

export const Price = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
`

export const EachPrice = styled.span`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
  font-style: italic;
`

export const ColorsCount = styled.span`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
`

export const CompareAtPrice = styled.del`
  margin-right: 5px;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.disabled?.opacityHex};
  font-style: italic;
`

export const Description = styled.p`
  padding-top: 2px;
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  ${typography.bodySmall};
`

export const SelectorsContainer = styled.form`
  display: grid;
  grid-gap: 16px;
  align-items: center;
  padding-top: 16px;
  ${mq.maxWidth("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Content = styled.div`
  ${typography.body};
`

export const ImgContainer = styled.div`
  position: relative;
`

export const HoverImgContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const TagContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`

export const ShopButton = styled.div`
  margin-top: 24px;
`

export const BottomDetailLabel = styled.div`
  padding-top: 16px;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
  font-style: italic;
`
