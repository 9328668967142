import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { addSlash } from "utils/stringUtils"
import { useSpring } from "react-spring"
import {
  Title,
  SubTitlePrice,
  ProductItemContainer,
  ImgContainer,
  Price,
  CompareAtPrice,
  EachPrice,
  ShopButton,
  SelectorsContainer,
  HoverImgContainer,
  TagContainer,
  BottomDetailLabel,
  ColorsCount,
  SubtitleContainer,
} from "./ProductItem.styles"
import { ProductItemAddToCart } from "./ProductItemAddToCart"
import { useVariantOptions } from "hooks/useVariantOptions"
import { Button, ButtonVariant } from "components/UI/Button/Button"
import { usdFmt } from "utils/priceUtils"
import { templateReplace } from "utils/stringUtils"
import { tracking } from "utils/tracking"
import { generatePriceRange } from "utils/productUtils"
import { ModeType, ProductListItemType, ViewType } from "typings/modules"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { Tag } from "components/UI/Tag/Tag"
import { ReviewStarsWithNumber } from "components/YotpoReviewList/ReviewStarsWithNumber"
import { VariantOptions } from "components/Products/VariantOptions"
import { useMinReviewsNumber } from "hooks/useMinReviewsNumber"

export type LinkModeType = "link" | "add" | "shop"
type productItemProps = {
  linkMode: LinkModeType
  item: ProductListItemType
  position: number
  mode: ModeType
  algoliaQueryId?: string
  mobileView?: ViewType
}

export const ProductItem: React.FC<productItemProps> = ({
  linkMode,
  item,
  position,
  mode,
  algoliaQueryId,
  mobileView,
  ...props
}: productItemProps) => {
  const {
    selectedVariantId,
    selectedVariant,
    image,
    imageAlt,
    hoverImage,
    hoverImageAlt,
    variantTypes,
    updateSelectedVariant,
    selectableOptions,
  } = useVariantOptions(item)
  const [active, setActive] = useState(false)
  const { isMobile, isTablet } = useCommonMediaQuery()
  const isMobileTablet = isMobile || isTablet
  const activeStyle = useSpring({ opacity: active ? 1 : 0 })
  const productPathPrefix = useProductPathPrefix()
  const path = item?.item?.product?.path?.current ?? ""
  const link = addSlash(`${productPathPrefix}${path}`)
  const productLink = selectedVariantId
    ? `${link}?variant=${selectedVariantId}`
    : link
  const count = item.item.product?.numberOfItemsInSet || 0
  const price = selectedVariant?.price ?? ""
  const usdPrice = price ? usdFmt(price) : ""
  const multi = count > 0
  const eachPrice = multi ? parseFloat(price) / count : 0
  const itemText = item.item.product?.pricePerItemText || ""
  const priceEach = multi ? templateReplace(itemText, usdFmt(eachPrice)) : ""
  const priceEachText = multi ? priceEach : ""
  const priceRange = generatePriceRange(item.item, item?.product)
  const product = item?.item?.product
  const trackPDPLink = () => {
    tracking.productClicked(
      item?.item?.product,
      selectedVariant,
      productLink,
      position,
      algoliaQueryId
    )
  }
  const bottomDetailLabel = item?.item?.detailLabel
  const tag = selectedVariant?.tag || product?.tag
  const isGrid = mobileView === "grid"
  const colorsCount = selectableOptions?.Color?.length || 0
  const minReviewsNumber = useMinReviewsNumber()
  const showReviews =
    (item.reviews?.bottomline.totalReview || 0) >= minReviewsNumber
  if (!image) return null
  return (
    <ProductItemContainer {...props}>
      <div>
        <ImgContainer
          onMouseOver={() => !isMobileTablet && setActive(true)}
          onMouseLeave={() => !isMobileTablet && setActive(false)}
        >
          <Link to={productLink} onClick={trackPDPLink}>
            <GatsbyImage image={image?.gatsbyImage} alt={imageAlt} />
          </Link>
          {hoverImage && (
            <HoverImgContainer style={activeStyle}>
              <Link tabIndex={-1} to={productLink} onClick={trackPDPLink}>
                <GatsbyImage
                  image={hoverImage?.gatsbyImage}
                  alt={hoverImageAlt}
                />
              </Link>
            </HoverImgContainer>
          )}
          {tag && (
            <TagContainer>
              <Tag tag={tag} />
            </TagContainer>
          )}
        </ImgContainer>
        <Title
          to={productLink}
          onClick={trackPDPLink}
          $mode={mode}
          $isGrid={isGrid}
        >
          {item.item.title}
        </Title>
        <SubTitlePrice $isGrid={isGrid && isMobile}>
          {showReviews && (
            <ReviewStarsWithNumber
              starCount={item.reviews?.bottomline.averageScore ?? 0}
              reviewCount={item.reviews?.bottomline.totalReview}
              clickable={false}
              mode={mode}
            />
          )}
          <SubtitleContainer $isGrid={isGrid && isMobile}>
            <Price>
              {priceRange ? (
                priceRange
              ) : (
                <>
                  {selectedVariant?.compareAtPrice && (
                    <CompareAtPrice>
                      {usdFmt(selectedVariant?.compareAtPrice)}
                    </CompareAtPrice>
                  )}
                  {usdPrice} <EachPrice>{priceEachText}</EachPrice>
                </>
              )}
            </Price>
            {isGrid && isMobile && colorsCount > 1 && (
              <ColorsCount>{colorsCount} Colors</ColorsCount>
            )}
          </SubtitleContainer>
        </SubTitlePrice>
        {variantTypes.length > 0 && (!isMobile || !isGrid) && (
          <SelectorsContainer
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <VariantOptions
              selectedVariant={selectedVariant}
              updateSelectedVariant={updateSelectedVariant}
              selectableOptions={selectableOptions}
              mode={mode}
            />
          </SelectorsContainer>
        )}
        {bottomDetailLabel && (
          <BottomDetailLabel>{bottomDetailLabel}</BottomDetailLabel>
        )}
      </div>
      {!isGrid && linkMode === "add" && (
        <ProductItemAddToCart selectedVariant={selectedVariant} mode={mode} />
      )}
      {!isGrid && linkMode === "shop" && (
        <ShopButton>
          <Button
            fullWidth
            variant={`secondary-${mode}` as ButtonVariant}
            data-test="product-page-submit"
            aria-label="Shop Now Button"
            onClick={trackPDPLink}
            to={productLink}
          >
            Shop Now
          </Button>
        </ShopButton>
      )}
    </ProductItemContainer>
  )
}
