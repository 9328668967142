import React, { useContext } from "react"
import styled from "styled-components"
import { ModeType, MergedVariant } from "typings/modules"
import { StoreContext } from "contexts/StoreContext"
import { Button, ButtonVariant } from "components/UI/Button/Button"

type ProductItemAddToCartProps = {
  selectedVariant?: MergedVariant
  mode: ModeType
}

const StyledForm = styled.form`
  width: 100%;
  margin-top: 24px;
  /* TODO: See why Carousel might cut out the last pixel */
  margin-bottom: 1px;
`

export const ProductItemAddToCart = ({
  selectedVariant,
  mode,
}: ProductItemAddToCartProps) => {
  const { addVariantToCart, setCartOpen } = useContext(StoreContext)
  const availableForSale = selectedVariant?.availableForSale
  const buttonText = availableForSale ? "Add to Cart" : "Sold Out"
  const buttonVariant = `secondary-${mode}` as ButtonVariant

  return (
    <StyledForm
      onSubmit={event => {
        event.preventDefault()
        if (availableForSale) {
          addVariantToCart(selectedVariant?.id, 1)
          setCartOpen(true)
        }
      }}
    >
      <Button
        fullWidth
        variant={availableForSale ? buttonVariant : "disabled"}
        type="submit"
        data-test="product-page-submit"
        aria-label={buttonText}
      >
        {buttonText}
      </Button>
    </StyledForm>
  )
}
